import React from 'react';
import Typist from 'react-typist';
import './App.css';
import 'react-typist/dist/Typist.css';

const heart = [
  "    ♥♥♥♥♥♥       ♥♥♥♥♥♥",
  "   ♥♥♥♥♥♥♥♥♥♥   ♥♥♥♥♥♥♥♥♥♥",
  " ♥♥♥♥♥♥♥♥♥♥♥♥♥ ♥♥♥♥♥♥♥♥♥♥♥♥♥",
  "♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥",
  "♥♥♥♥♥♥♥♥♥♥♥小民♥恩淇♥♥♥♥♥♥♥♥♥♥♥",
  "♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥",
  " ♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥",
  "   ♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥",
  "    ♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥",
  "      ♥♥♥♥♥♥♥♥♥♥♥♥♥♥♥",
  "        ♥♥♥♥♥♥♥♥♥♥♥",
  "         ♥♥♥♥♥♥♥",
  "           ♥♥♥",
  "            ♥"
];

const cursor = {
  show: true,
  blink: true,
  hideWhenDone: true,
  hideWhenDoneDelay: 1000,
};

function App() {
  return (
    <div className="App">
      <Typist
        avgTypingDelay={250}
        stdTypingDelay={120}
        startDelay={3000}
        cursor={cursor}
        onCharacterTyped={() => {
          document.documentElement.scrollTop = document.body.clientHeight;
        }}
      >
        {`
欧恩淇，小可爱 👩🏻


454 天天天天天天天天前，🌿 和 🌧 的西湖岸边，你胖胖的 🖐🏻 紧紧抓住了我的，手心滚烫得像烧焦的煤球，每一个摄氏度灼然似火，仿如昨日


四百天来，我和你一起成长，成熟，见世，见笑。以孩童之心做大人的事，你给我心底打满了面对未来生活的勇气 💪🏻


不是我们在一起过了 454 天，而是和你一起过一天这件事发生了 454 次


这件事实在太美好，我还想它再发生几万次


我们一起数，不断数，慢慢数，数到我们老的说不动话的那天 👴🏻🧓🏻


用彼此的老花眼神写下爱你，好么？

`} 
        <Typist.Delay ms={2000} />
        <br />
        <div style={{ color: '#ff4c98' }}>
          {heart.map(item => <div>{item}</div>)}
        </div>
        <br />
        泉源在左，淇水在右。今生有幸，与你同行。😊
       </Typist>
    </div>
  );
}

export default App;
